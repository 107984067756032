import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'slicknav/dist/slicknav.min.css';

const Header = () => {
  const location = useLocation();

  useEffect(() => {
    const loadScripts = async () => {
      try {
        const jQuery = await import('jquery');
        window.$ = jQuery.default;
        window.jQuery = jQuery.default;

        await import('slicknav/dist/jquery.slicknav.min.js');
        console.log('SlickNav loaded successfully.');

        if (typeof window.$.fn.slicknav !== 'undefined') {
          console.log('Initializing SlickNav...');
          window.$('.mobile-menu').slicknav({
            prependTo: '#mobile-menu-wrap',
            allowParentLinks: true,
          });
        } else {
          console.error('SlickNav is not defined.');
        }
      } catch (error) {
        console.error('Error loading SlickNav:', error);
      }
    };

    const initializeSlicknav = () => {
      if (document.readyState === 'complete' || document.readyState === 'interactive') {
        loadScripts();
      } else {
        document.addEventListener('DOMContentLoaded', loadScripts);
      }
    };

    initializeSlicknav();

    // Event listeners for open and close actions
    const handleOpenCanvas = () => {
      window.$('.offcanvas-menu-wrapper').addClass('show-offcanvas-menu-wrapper');
      window.$('.offcanvas-menu-overlay').addClass('active');
    };

    const handleCloseCanvas = () => {
      window.$('.offcanvas-menu-wrapper').removeClass('show-offcanvas-menu-wrapper');
      window.$('.offcanvas-menu-overlay').removeClass('active');
    };

    // Attach event listeners
    window.$('.canvas-open').on('click', handleOpenCanvas);
    window.$('.canvas-close, .offcanvas-menu-overlay').on('click', handleCloseCanvas);

    // Cleanup event listeners on component unmount
    return () => {
      window.$('.canvas-open').off('click', handleOpenCanvas);
      window.$('.canvas-close, .offcanvas-menu-overlay').off('click', handleCloseCanvas);
      document.removeEventListener('DOMContentLoaded', loadScripts);
    };
  }, [location]); // Reinitialize SlickNav on location change

  return (
    <>
      <div id="preloder">
        <div className="loader" />
      </div>

      <div className="offcanvas-menu-overlay" />

      <div className="canvas-open">
        <i className="icon_menu" />
      </div>

      <div className="offcanvas-menu-wrapper">
        <div className="canvas-close">
          <i className="icon_close" />
        </div>

        <nav className="mainmenu mobile-menu">
          <ul>
            <li className={location.pathname === '/' ? 'active' : ''}>
              <Link to="/">Home</Link>
            </li>
            <li className={location.pathname === '/rooms' ? 'active' : ''}>
              <Link to="/rooms">Rooms</Link>
            </li>
            <li className={location.pathname === '/facilities' ? 'active' : ''}>
              <Link to="/facilities">Facilities</Link>
            </li>
            <li className={location.pathname === '/photos' ? 'active' : ''}>
              <Link to="/photos">Photo Gallery</Link>
            </li>
            <li className={location.pathname === '/reservation' ? 'active' : ''}>
              <Link to="/reservation">Reservation & Enquiry</Link>
            </li>
          </ul>
        </nav>

        <div id="mobile-menu-wrap" />
        <div className="top-social">
          <Link to="#">
            <i className="fa fa-facebook" />
          </Link>
          <Link to="#">
            <i className="fa fa-twitter" />
          </Link>
          <Link to="#">
            <i className="fa fa-tripadvisor" />
          </Link>
          <Link to="#">
            <i className="fa fa-instagram" />
          </Link>
        </div>

        <ul className="top-widget">
          <li>
            <i className="fa fa-phone" /> (02) 4991 2355
          </li>
          <li>
            <i className="fa fa-envelope" /> relax@hvtr.au
          </li>
        </ul>
      </div>

      <header className="header-section">
        <div className="top-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <ul className="tn-left">
                  <li>
                    <i className="fa fa-phone" /> (02) 4991 2355
                  </li>
                  <li>
                    <i className="fa fa-envelope" /> relax@hvtr.au
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="tn-right">
                  <div className="top-social">
                    <Link to="#">
                      <i className="fa fa-facebook" />
                    </Link>
                    <Link to="#">
                      <i className="fa fa-twitter" />
                    </Link>
                    <Link to="#">
                      <i className="fa fa-tripadvisor" />
                    </Link>
                    <Link to="#">
                      <i className="fa fa-instagram" />
                    </Link>
                  </div>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    to="https://apac.littlehotelier.com/properties/huntervalleytravellersdirect"
                    className="bk-btn"
                  >
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="menu-item">
          <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <div className="logo">
                  <Link to="/">
                    <img src="img/logo.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="nav-menu">
                  <nav className="mainmenu">
                    <ul>
                      <li className={location.pathname === '/' ? 'active' : ''}>
                        <Link to="/">Home</Link>
                      </li>
                      <li className={location.pathname === '/rooms' ? 'active' : ''}>
                        <Link to="/rooms">Rooms</Link>
                      </li>
                      <li className={location.pathname === '/facilities' ? 'active' : ''}>
                        <Link to="/facilities">Facilities</Link>
                      </li>
                      <li className={location.pathname === '/photos' ? 'active' : ''}>
                        <Link to="/photos">Photo Gallery</Link>
                      </li>
                      <li className={location.pathname === '/reservation' ? 'active' : ''}>
                        <Link to="/reservation">Reservation & Enquiry</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
