import React from "react";
import OwlCarousel from "react-owl-carousel";

const Testimonials = () => {
  // Options for Owl Carousel
  const options = {
    items: 1,
    dots: false,
    autoplay: true,
    loop: true,
    smartSpeed: 1500,
    nav: true,
    navText: ["<i class='arrow_left'></i>", "<i class='arrow_right'></i>"],
  };

  // Content for Owl Carousel
  const items = [
    <div className="ts-item" key={1}>
      <p>
        Thank you to Sonya & Ash for exceptional customer service.
        <br />
        Rooms immaculately clean and well presented. We enjoyed the common room
        facilities to eat our breakfast, lunch & dinner with our friends. Being
        able to BBQ our steak was terrific. Many thanks for the secure parking
        of our motorbike.
      </p>
      <div className="ti-author">
        <div className="rating">
          <i className="icon_star" />
          <i className="icon_star" />
          <i className="icon_star" />
          <i className="icon_star" />
          <i className="icon_star" />
        </div>
        <h5> - Julie B</h5>
      </div>
      <img src="img/testimonial-logo.png" alt="" />
    </div>,
    <div className="ts-item" key={2}>
      <p>
        An affordable motel with easy access to the Hunter Valley attractions
        and the facilities of the city of Cessnock nearby. Comfortable beds, air
        conditioning, and good wifi. Highly recommended for families as well as
        solo.
      </p>
      <div className="ti-author">
        <div className="rating">
          <i className="icon_star" />
          <i className="icon_star" />
          <i className="icon_star" />
          <i className="icon_star" />
          <i className="icon_star" />
        </div>
        <h5> - DrLaurie123</h5>
      </div>
      <img src="img/testimonial-logo.png" alt="" />
    </div>,
    <div className="ts-item" key={3}>
      <p>
        A rushed trip so a budget stay, I couldn't be happier with the service, politeness, quietness, and spick and span cleanness of our unit. The manager and his wife were very polite and helpful even though we arrived late.
      </p>
      <div className="ti-author">
        <div className="rating">
          <i className="icon_star" />
          <i className="icon_star" />
          <i className="icon_star" />
          <i className="icon_star" />
          <i className="icon_star" />
        </div>
        <h5> - Barcoo</h5>
      </div>
      <img src="img/testimonial-logo.png" alt="" />
    </div>,
    <div className="ts-item" key={4}>
    <p>
      Stayed a couple of nights in the new year (2 and 3 Jan, 2020). This I would deem as budget accommodation $85 - $110 / night ( weekday/weekend rates) Management and staff were friendly and helpful. Rooms, tho on the smaller side, were clean and tidy. Would happily stay here again
    </p>
    <div className="ti-author">
      <div className="rating">
        <i className="icon_star" />
        <i className="icon_star" />
        <i className="icon_star" />
        <i className="icon_star" />
        <i className="icon_star_alt" />
      </div>
      <h5> - Dave N</h5>
    </div>
    <img src="img/testimonial-logo.png" alt="" />
  </div>,
    <div className="ts-item" key={5}>
    <p>
        Small but scrupulously clean hotel rooms. If all you are after is a place to sleep, as I was, and don’t need all the bells and whistles, then I can recommend this place. The manager was very friendly and helpful, and I had a good nights sleep!
    </p>
    <div className="ti-author">
      <div className="rating">
        <i className="icon_star" />
        <i className="icon_star" />
        <i className="icon_star" />
        <i className="icon_star" />
        <i className="icon_star_alt" />
      </div>
      <h5> - Dennis S</h5>
    </div>
    <img src="img/testimonial-logo.png" alt="" />
  </div>,
  ];

  return (
    <section className="testimonial-section spad">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <span>Testimonials</span>
              <h2>What Customers Say?</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <OwlCarousel
              className="testimonial-slider owl-carousel"
              {...options}
            >
              {items}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
