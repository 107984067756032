import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import Lightbox from "../Components/Common/Lightbox";

const Gallery = () => {
    
  const [selectedIndex, setSelectedIndex] = useState(null);

  const images = [
    { src: "img/gallery/15-1030x686.jpg", tag: "Motel Front" },
    { src: "img/gallery/reception2-1-1030x773.jpeg", tag: "Reception Area" },
    { src: "img/gallery/reception-1-1030x773.jpeg", tag: "Reception Area" },
    { src: "img/gallery/gallery-1-773x1030.jpeg", tag: "Hall way" },
    { src: "img/gallery/double-room1-1-928x1030.jpeg", tag: "Double Room" },
    { src: "img/gallery/07-1030x686.jpg", tag: "Travellers Motel Bathroom" },
    { src: "img/gallery/dining-area-1-1-1030x773.jpeg", tag: "Dining Area" },
    { src: "img/gallery/deluxe3-1-1030x773.jpeg", tag: "Deluxe Room" },
    { src: "img/gallery/deluxe2-1-897x1030.jpeg", tag: "Deluxe Room" },
    { src: "img/gallery/deluxe-1-1030x773.jpeg", tag: "Deluxe Room" },
    {
      src: "img/gallery/11-1030x686.jpg",
      tag: "Motel private En-suite bathrooms",
    },
    { src: "img/gallery/common-area-4-1-1030x984.jpeg", tag: "Common Area" },
    { src: "img/gallery/common-area-3-1-1030x664.jpeg", tag: "Common Area" },
    { src: "img/gallery/common-area-2-1-1030x917.jpeg", tag: "Common Area" },
    { src: "img/gallery/common-area-1-1-861x1030.jpeg", tag: "Common Area" },
    { src: "img/gallery/barbeque-1-1030x921.jpeg", tag: "Barbeque" },
  ];

  const handleImageClick = (index) => {
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setSelectedIndex(null);
  };

  const handlePrev = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleNext = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <>
      <Header />

      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-text">
                <h2>Photo Gallery</h2>

                <div className="bt-option">
                  <Link to="/">Home</Link>

                  <span>Photo Gallery</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section blog-page spad gallery">
        <div className="container">
          <div className="row">
            {images.map((image, index) => (
              <div className="col-lg-3 col-md-6" key={index}>
                <div
                  className="blog-item set-bg"
                  style={{ backgroundImage: `url(${image.src})` }}
                  onClick={() => handleImageClick(index)}
                >
                  <div className="bi-text">
                    <span className="b-tag">{image.tag}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Lightbox
        image={selectedIndex !== null ? images[selectedIndex].src : null}
        onClose={handleClose}
        onPrev={handlePrev}
        onNext={handleNext}
      />

      <Footer />
    </>
  );
};

export default Gallery;
