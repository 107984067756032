import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';

const Contact = () => {
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const MySwal = withReactContent(Swal);

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validate = () => {
    let validationErrors = {};
    if (!formData.name) {
      validationErrors.name = "Name is required";
    }
    if (!formData.email) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Email address is invalid";
    }
    if (!formData.message) {
      validationErrors.message = "Message is required";
    }
    if (!recaptchaToken) {
      validationErrors.recaptcha = "Please complete the reCAPTCHA";
    }
    return validationErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setSuccessMessage('');
      setIsLoading(true);
      
      const data = {
        ...formData,
        recaptchaToken: recaptchaToken
      };

      axios.post('https://new.hvtr.au/send_email.php', data)
        .then(response => {
          setIsLoading(false);
          if (response.data.status === 'success') {
            setSuccessMessage('Email sent successfully!');

            MySwal.fire({
              title: 'Success!',
              text: 'Thank you! We have received your message and will get back to you shortly. We appreciate your patience and look forward to assisting you.',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false
            });

            // Clear form fields
            setFormData({ name: '', email: '', message: '' });
            setRecaptchaToken(null);
          } else {

            MySwal.fire({
              title: 'Error!',
              text: 'Something went wrong. Please try again later.',
              icon: 'error',
              timer: 2000,
              showConfirmButton: false
            });

            setErrors({ form: response.data.message });
          }
        })
        .catch(error => {
          setIsLoading(false); 
          console.log('error ',error);

          MySwal.fire({
            title: 'Error!',
            text: 'Something went wrong. Please try again later.',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false
          });

          setErrors({ form: 'Failed to send email. Please try again later.' });
        });
    }
  };

  return (
    <>
      <Header />

      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-text">
                <h2>Reservation / Enquiry</h2>
                <div className="bt-option">
                  <Link to="/">Home</Link>
                  <span>Reservation / Enquiry</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="contact-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-5">
              <div className="contact-text text-center">
                <h2>RESERVATION / ENQUIRY</h2>
                {successMessage && <p className="success-message">{successMessage}</p>}
                {errors.form && <p className="error-message">{errors.form}</p>}
              </div>
            </div>

            <div className="col-lg-7 ">
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && <p className="error">{errors.name}</p>}
                  </div>

                  <div className="col-lg-6">
                    <input
                      type="text"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>

                  <div className="col-lg-12">
                    <textarea
                      name="message"
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                    {errors.message && <p className="error">{errors.message}</p>}
                  </div>

                  <div className="col-lg-8">
                    <ReCAPTCHA
                      sitekey="6LdrqA8qAAAAAPXIX4TKn2z7DczizrZf6VNF7DKm" 
                      onChange={handleRecaptchaChange}
                      style={{ width: "73%", float: "left" }}
                    />
                    {errors.recaptcha && <p className="error" style={{ marginTop: "85px" }}>{errors.recaptcha}</p>}
                  </div>

                  <div className="col-lg-4">
                    <button type="submit" disabled={isLoading}>
                      {isLoading ? "Sending..." : "Submit Now"}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-lg-4 offset-lg-1">
              <h4>GET YOUR RESERVATION TODAY!</h4>

              <div className="contact-text mt-1">
                <p>
                  <a
                    type="button"
                    target="_blank"
                    rel="noreferrer"
                    href="https://apac.littlehotelier.com/properties/huntervalleytravellersdirect"
                  >
                    Book Now
                  </a>
                </p>

                <table>
                  <tbody>
                    <tr>
                      <td className="c-o">Address:</td>
                      <td>35A Colliery St, Aberdare NSW 2325</td>
                    </tr>
                    <tr>
                      <td className="c-o">Phone:</td>
                      <td>
                        <a href="tel:(02) 4991 2355">(02) 4991 2355</a>
                      </td>
                    </tr>
                    <tr>
                      <td className="c-o">Email:</td>
                      <td>
                        <a href="mailto:relax@hvtr.au">relax@hvtr.au</a>
                      </td>
                    </tr>
                    <tr>
                      <td className="c-o">Fax:</td>
                      <td>
                        <a href="faxto:(02) 4991 2619">(02) 4991 2619</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="map">
            <iframe
              title="Google Map Address"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3351.9915738178456!2d151.37559831575675!3d-32.8454747741051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b73346143ea7e55%3A0xd5023280d0af1af0!2sHunter%20Valley%20Travellers%20Rest%20Motel!5e0!3m2!1sen!2sin!4v1655898652058!5m2!1sen!2sin"
              height={470}
              style={{ border: 0 }}
              allowFullScreen=""
            />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Contact;
