import React from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";

const Rooms = () => {
  return (
    <>
      <Header />

      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-text">
                <h2>Our Rooms</h2>

                <div className="bt-option">
                  <Link to="/">Home</Link>

                  <span>Rooms</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="rooms-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="room-item">
                <img src="img/room/Queen-Room-Standard.jpg" alt="" />
                <div className="ri-text">
                  <h4>Queen Room Standard </h4>
                  <h3>
                    140$<span>/Pernight</span>
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td className="r-o">Fridge</td>
                        <td>Air Conditioned</td>
                      </tr>
                      <tr>
                        <td className="r-o">Television</td>
                        <td>Wireless Internet</td>
                      </tr>
                      <tr>
                        <td className="r-o">Microwave</td>
                        <td>Electric kettle</td>
                      </tr>
                      <tr>
                        <td className="r-o" colSpan={2}>
                          Linen & Towels Provided
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Link
                    to="https://apac.littlehotelier.com/reservations/huntervalleytravellersdirect/139763?locale=en"
                    target="_blank"
                    className="primary-btn"
                  >
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-item">
                <img src="img/room/Deluxe-King-Room-Pet-Friendly-Standard.jpg" alt="" />
                <div className="ri-text">
                  <h4>Deluxe King Room (Pet Friendly) Standard</h4>
                  <h3>
                    150$<span>/Pernight</span>
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td className="r-o">Housekeeping</td>
                        <td>Fridge - Bar Fridge</td>
                      </tr>
                      <tr>
                        <td className="r-o">Towels</td>
                        <td>Tea/Coffee Maker</td>
                      </tr>
                      <tr>
                        <td className="r-o">Microwave</td>
                        <td>Air conditioned</td>
                      </tr>
                      <tr>
                        <td className="r-o">Internet Access</td>
                        <td>Internet Access</td>
                      </tr>
                      <tr>
                        <td className="r-o">Bath</td>
                        <td>Electric kettle</td>
                      </tr>
                      <tr>
                        <td className="r-o">TV</td>
                        <td>Lamp</td>
                      </tr>
                    </tbody>
                  </table>
                  <Link
                    to="https://apac.littlehotelier.com/reservations/huntervalleytravellersdirect/166949?locale=en"
                    target="_blank"
                    className="primary-btn"
                  >
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-item">
                <img src="img/room/Family-Room-Pet-Friendly-Standard.jpg" alt="" />
                <div className="ri-text">
                  <h4>Family Room (Pet Friendly) Standard</h4>
                  <h3>
                    220$<span>/Pernight</span>
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td className="r-o">Double Beds</td>
                        <td>Housekeeping</td>
                      </tr>
                      <tr>
                        <td className="r-o">Coffee Maker</td>
                        <td>Towels</td>
                      </tr>
                      <tr>
                        <td className="r-o">Microwave</td>
                        <td>Air conditioned</td>
                      </tr>
                      <tr>
                        <td className="r-o">Wifi</td>
                        <td>Television</td>
                      </tr>
                    </tbody>
                  </table>
                  <Link
                    to="https://apac.littlehotelier.com/reservations/huntervalleytravellersdirect/166950?locale=en"
                    target="_blank"
                    className="primary-btn"
                  >
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-item">
                <img src="img/room/Queen-Room-Pet-Friendly-Standard.jpg" alt="" />
                <div className="ri-text">
                  <h4>Queen Room (Pet Friendly) Standard</h4>
                  <h3>
                    90$<span>/Pernight</span>
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td className="r-o">Fridge</td>
                        <td>Television</td>
                      </tr>
                      <tr>
                        <td className="r-o">Microwave</td>
                        <td>Electric kettle</td>
                      </tr>
                      <tr>
                        <td className="r-o">Wifi</td>
                        <td>Air conditioned</td>
                      </tr>
                      <tr>
                        <td className="r-o" colSpan={2}>
                          Linen and Towels Provided.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Link to="https://apac.littlehotelier.com/reservations/huntervalleytravellersdirect/166951?locale=en" target="_blank" className="primary-btn">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-item">
                <img src="img/room/Twin-Room-Pet-Friendly-Standard.jpg" alt="" />
                <div className="ri-text">
                  <h4>Twin Room( Pet Friendly) Standard</h4>
                  <h3>
                    110$<span>/Pernight</span>
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td className="r-o">Fridge</td>
                        <td>Television</td>
                      </tr>
                      <tr>
                        <td className="r-o">Microwave</td>
                        <td>Electric kettle</td>
                      </tr>
                      <tr>
                        <td className="r-o">Wifi</td>
                        <td>Air conditioned</td>
                      </tr>
                      <tr>
                        <td className="r-o" colSpan={2}>
                          Linen and Towels Provided.
                        </td>
                      </tr>
                    </tbody>
                    </table>
                  <Link to="https://apac.littlehotelier.com/reservations/huntervalleytravellersdirect/166952?locale=en" target="_blank" className="primary-btn">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-item">
                <img src="img/room/Deluxe-King-Room-Standard.jpg" alt="" />
                <div className="ri-text">
                  <h4>Deluxe King Room Standard </h4>
                  <h3>
                    150$<span>/Pernight</span>
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td className="r-o">Hairdryer</td>
                        <td>Fridge - Bar Fridge</td>
                      </tr>
                      <tr>
                        <td className="r-o">Air Conditioning</td>
                        <td>Tea/Coffee Maker</td>
                      </tr>
                      <tr>
                        <td className="r-o">BBQ Area</td>
                        <td>En-Suite Bathroom</td>
                      </tr>
                      <tr>
                        <td className="r-o" colSpan={2}>Wireless Internet</td>
                      </tr>
                    </tbody>
                  </table>
                  <Link to="https://apac.littlehotelier.com/reservations/huntervalleytravellersdirect/139765?locale=en" target="_blank" className="primary-btn">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-item">
                <img src="img/room/Family-Room-Standard.jpg" alt="" />
                <div className="ri-text">
                  <h4>Family Room Standard</h4>
                  <h3>
                    210$<span>/Pernight</span>
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td className="r-o">Hairdryer</td>
                        <td>Fridge - Bar Fridge</td>
                      </tr>
                      <tr>
                        <td className="r-o">Microwave</td>
                        <td>Linen & Towels Provided</td>
                      </tr>
                      <tr>
                        <td className="r-o">Air-conditioning</td>
                        <td>En-Suite Bathroom</td>
                      </tr>
                      <tr>
                        <td className="r-o">Television</td>
                        <td>Wireless Internet</td>
                      </tr>
                    </tbody>
                  </table>
                  <Link to="https://apac.littlehotelier.com/reservations/huntervalleytravellersdirect/139765?locale=en" target="_blank" className="primary-btn">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-item">
                <img src="img/room/Twin-Room-Standard.jpg" alt="" />
                <div className="ri-text">
                  <h4>Twin Room Standard </h4>
                  <h3>
                    180$<span>/Pernight</span>
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td className="r-o">Microwave</td>
                        <td>Fridge - Bar Fridge</td>
                      </tr>
                      <tr>
                        <td className="r-o">Air-conditioning</td>
                        <td>Tea/Coffee Maker</td>
                      </tr>
                      <tr>
                        <td className="r-o">Television</td>
                        <td>King Beds</td>
                      </tr>
                      <tr>
                        <td className="r-o">Wifi</td>
                        <td>Linen & Towels Provided</td>
                      </tr>
                    </tbody>
                  </table>
                  <Link to="https://apac.littlehotelier.com/reservations/huntervalleytravellersdirect/139766?locale=en" target="_blank" className="primary-btn">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Rooms;
