import React, { useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

const Slider = () => {
  // Options for Owl Carousel
  const options = {
    loop: true,
    margin: 0,
    items: 1,
    dots: true,
    animateOut: "fadeOut",
    animateIn: "fadeIn",
    smartSpeed: 1200,
    autoplay: true,
    mouseDrag: false,
  };

  // Content for Owl Carousel
  const items = [
    <div
      key={1}
      className="hs-item set-bg"
    >
      <img src="/img/hero/hero-1.jpg" alt="Hunter Valley Motel" />
    </div>,
    <div
      key={2}
      className="hs-item set-bg"
    >
      <img src="/img/hero/common-area-4-1-1030x984.jpeg" alt="Hunter Valley Motel" />
    </div>,
    <div
      key={3}
      className="hs-item set-bg"
    >
      <img src="/img/hero/hero-1.jpg" alt="Hunter Valley Motel" />
    </div>,
  ];

  const [startDate, setStartDate] = useState(new Date().toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split("T")[0]);

  const onChange = (date) => {
    setStartDate(date);
    
    if(date > endDate) {
      setEndDate(date);
    }
  };
  
  const onEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    let url = `https://apac.littlehotelier.com/properties/huntervalleytravellersdirect?utf8=%E2%9C%93&locale=en&currency=AUD&start_date=${startDate}&end_date=2024-05-21 /+to+`
    window.open(url, "_blank");
    
  };

  return (
    <>
      <section className="hero-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="hero-text">
                <h1>HUNTER VALLEY TRAVELLERS REST MOTEL</h1>

                <br />
                <p>
                  A Quiet Place to Stay and Relax, Only 2 km to main street of
                  Cessnock & 10 mins to Vineyards Book directly on our official
                  website and get the best prices :)
                </p>

                <Link to="" className="primary-btn">
                  Discover Now
                </Link>
              </div>
            </div>

            <div className="col-xl-4 col-lg-5 offset-xl-2 offset-lg-1">
              <div className="booking-form">
                <h3>Booking Your Room</h3>

                <form onSubmit={handleSubmit}>
                  <div className="check-date">
                    <label htmlFor="date-in">Check In:</label>
                    <input
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      id="date-in"
                      value={startDate}
                      onChange={(e) => onChange(e.target.value)}
                    />
                  </div>

                  <div className="check-date">
                    <label htmlFor="date-out">Check Out:</label>
                    <input
                      type="date"
                      min={startDate}
                      id="date-out"
                      value={endDate}
                      onChange={(e) => onEndDateChange(e.target.value)}
                    />
                  </div>

                  <button type="submit">Check Availability</button>
                </form>

              </div>
            </div>
          </div>
        </div>

        <OwlCarousel
          className="owl-theme hero-slider owl-carousel autoHeightClass"
          {...options}
        >
          {items}
        </OwlCarousel>
      </section>
    </>
  );
};

export default Slider;
