import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import Rooms from "./Pages/Rooms";
import Facilities from "./Pages/Facilities";
import Gallery from "./Pages/Gallery";
import Contact from "./Pages/Contact";

const Router = () => {
  return (
    <LoaderHandler>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route path="/photos" element={<Gallery />} />
        <Route path="/reservation" element={<Contact />} />
      </Routes>
    </LoaderHandler>
  );
};

const LoaderHandler = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const hideLoader = () => {
      const preloader = document.getElementById("preloder");
      if (preloader) {
        preloader.style.display = "none";
      }
    };

    hideLoader(); // Hide loader on initial mount and route changes

    // Optionally, you can also listen to the window load event
    window.addEventListener("load", hideLoader);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("load", hideLoader);
    };
  }, [location]); // Dependency on location to trigger useEffect on route changes

  return children;
};

export default Router;