import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-text">
            <div className="row">
              <div className="col-lg-4">
                <div className="ft-about">
                  <div className="logo">
                    <h6>Hunter Valley Travellers Rest Motel</h6>
                  </div>
                  <p>
                  We provide some of the best Cessnock accommodation around with a tariff to suit your budget whilst offering all the hospitalities of a comfortable motel for a weekend away.
                  </p>
                  <div className="fa-social">
                    <Link to={void 0}>
                      <i className="fa fa-facebook" />
                    </Link>
                    <Link to={void 0}>
                      <i className="fa fa-twitter" />
                    </Link>
                    <Link to={void 0}>
                      <i className="fa fa-tripadvisor" />
                    </Link>
                    <Link to={void 0}>
                      <i className="fa fa-instagram" />
                    </Link>
                    <Link to={void 0}>
                      <i className="fa fa-youtube-play" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 offset-lg-1">
                <div className="ft-contact">
                  <h6>Contact Us</h6>
                  <ul>
                    <li>(02) 4991 2355</li>
                    <li>relax@hvtr.au</li>
                    <li>35A Colliery St, Aberdare NSW 2325</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 offset-lg-1">
                <div className="ft-newslatter">
                  <h6>New latest</h6>
                  <p>Get the latest updates and offers.</p>
                  <form action="#" className="fn-form">
                    <input type="text" placeholder="Email" />
                    <button type="submit">
                      <i className="fa fa-send" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-option">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <ul>
                  <li>
                    <Link to="/reservation">Contact Us</Link>
                  </li>
                  
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="co-text">
                  <p>
                    All contents are the property of Hunter Valley Travelers Rest Motel ©2024
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
