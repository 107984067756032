import React from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";

const Facilities = () => {
  return (
    <>
      <Header />

      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-text">
                <h2>Facilities</h2>

                <div className="bt-option">
                  <Link to="/">Home</Link>

                  <span>Facilities</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="aboutus-page-section spad">
        <div className="container">

          <div className="about-page-text">
            <div className="row">
              <div className="col-lg-12">
                <div className="ap-title">
                  <h2>Welcome to Hunter Valley Travellers Rest Motel</h2>
                  <p>
                  <img src="/img/8ad84a87.jpg" alt="" />
                  </p>
                  <p>
                    {" "}
                    We invite you to share our good old fashioned country
                    service. The Hunter Valley Travellers Rest Motel is located
                    in Cessnock within easy reach of the picturesque vineyards
                    of the Hunter Valley and the Hunter Valley Gardens. We
                    provide some of the best Cessnock accommodation around with
                    a tariff to suit your budget whilst offering all the
                    hospitalities of a comfortable motel for a weekend away.
                  </p>

                  <p>
                    Hunter Valley Travelers Rest Motel offers comfortable
                    air-conditioned rooms with a flat-screen TV and a private
                    bathroom. Guests can enjoy and outdoor meal using the
                    barbecue area. Each room at Travelers Rest Motel includes a
                    microwave, a refrigerator and tea/coffee making facilities.
                    All rooms are very quiet as they are located away from the
                    road.
                  </p>

                  <p>
                    The property is a 5-minute drive to the cafes and
                    restaurants in Cessnock town center. Guests have access to a
                    spacious garden area and a tour desk. The vineyards of the
                    Hunter Valley are just a 10-minute drive from Travelers Rest
                    Motel.
                  </p>
                </div>
              </div>

              <div className="row col-lg-12">
                <div className="col-lg-3">
                  <ul className="ap-services">
                    <li>
                      <i className="icon_check" /> 17 airconditioned rooms
                    </li>
                    <li>
                      <i className="icon_check" /> Reverse-cycle
                      air-conditioning
                    </li>
                    <li>
                      <i className="icon_check" /> Private En-suite bathrooms
                    </li>
                    <li>
                      <i className="icon_check" /> Walk in shower
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3">
                  <ul className="ap-services">
                    <li>
                      <i className="icon_check" /> Electric blankets
                    </li>
                    <li>
                      <i className="icon_check" /> Tea & coffee facilities
                    </li>
                    <li>
                      <i className="icon_check" /> LCD Digitial television
                    </li>
                    <li>
                      <i className="icon_check" /> Refrigerator - bar size
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3">
                  <ul className="ap-services">
                    <li>
                      <i className="icon_check" /> Free off street parking
                    </li>
                    <li>
                      <i className="icon_check" /> Wireless internet available
                    </li>
                    <li>
                      <i className="icon_check" /> Hairdryers available
                    </li>
                    <li>
                      <i className="icon_check" /> Toasters available
                    </li>
                    <li>
                      <i className="icon_check" /> Ironing board & iron avail.
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3">
                  <ul className="ap-services">
                    <li>
                      <i className="icon_check" /> B.B.Q. facilities
                    </li>
                    <li>
                      <i className="icon_check" /> Opening windows
                    </li>
                    <li>
                      <i className="icon_check" /> Facsimilie & photocopying
                      facilities
                    </li>
                    <li>
                      <i className="icon_check" /> Toasters available
                    </li>
                    <li>
                      <i className="icon_check" /> Facsimilie & photocopying
                      facilities
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </section>

      <section className="video-section set-bg"  style={{ backgroundImage: "url('/img/video-bg.jpg')" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="video-text">
                <h2>Discover Our Hotel &amp; Services.</h2>
                <p>
                  It S Hurricane Season But We Are Visiting Hilton Head Island
                </p>
       
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Facilities;
