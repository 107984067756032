import React from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Common/Header";
import Slider from "../Components/Common/Home/Slider";
import Testimonials from "../Components/Common/Home/Testimonials";
import Footer from "../Components/Common/Footer";

const Home = () => {
  return (
    <>
      <Header />

      <Slider />

      <section className="aboutus-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-text">
                <div className="section-title">
                  <span>About Us</span>
                  <h2>
                    WELCOME TO <br />
                    TRAVELLERS REST MOTEL
                  </h2>
                </div>
                <p className="f-para">
                  We invite you to share our good old fashioned country service.
                  The Hunter Valley Travellers Rest Motel is located in Cessnock
                  within easy reach of the picturesque vineyards of the Hunter
                  Valley and the Hunter Valley Gardens.
                </p>
                <Link to="#" className="primary-btn about-btn">
                  Read More
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-pic">
                <div className="row">
                  <div className="col-sm-6">
                    <img src="img/about/about-1.jpg" alt="" />
                  </div>
                  <div className="col-sm-6">
                    <img src="img/about/about-2.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <span>What We Offer</span>
                <h2>Property Features</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="service-item">
                <i className="flaticon-044-clock-1" />
                <h4>24 hour Reception</h4>
                <p>
                  Experience unmatched convenience with our 24-hour reception,
                  always ready to assist you anytime, day or night, for a
                  seamless stay.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="service-item">
                <i className="flaticon-033-dinner" />
                <h4>BBQ Area</h4>
                <p>
                  Unwind and savor delicious moments in our BBQ area, the
                  perfect spot for grilling and gathering with friends and
                  family.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="service-item">
                <i className="flaticon-029-wifi" />
                <h4>WiFi Internet</h4>
                <p>
                  Stay connected with our high-speed WiFi internet, available
                  throughout the property to keep you online and in touch.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="service-item">
                <i className="flaticon-025-pillow" />
                <h4>Pet friendly</h4>
                <p>
                  We understand that pets are family too. Bring them along for
                  just an extra $30 per pet per day. Enjoy the beautiful
                  surroundings together!
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="service-item">
                <i className="flaticon-003-air-conditioner" />
                <h4>Air-Conditioning</h4>
                <p>
                  Relish in absolute comfort with our state-of-the-art <br />
                  air-conditioning, ensuring a refreshing atmosphere even in the
                  hottest of days.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="service-item">
                <i className="flaticon-005-tea" />
                <h4>Coffee Maker</h4>
                <p>
                  Elevate your mornings with the convenience of a coffee maker
                  in your room, crafting the perfect brew to kickstart your day.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="service-item">
                <i className="flaticon-036-parking" />
                <h4>Free Parking</h4>
                <p>
                  Experience the ease of free off-street parking, ensuring safe
                  and hassle-free arrivals and departures during your visit.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="service-item">
                <i className="flaticon-019-television" />
                <h4>TV</h4>
                <p>
                  Indulge in immersive entertainment on our sleek flat-screen
                  TVs, enhancing your stay with vivid visuals and crisp clarity.
                </p>
              </div>
            </div>
          </div>

          <div className="about-text" style={{ marginTop: "25px" }}>
            <Link to="/facilities" className=" primary-btn about-btn">
              View All Facilities
            </Link>
          </div>
        </div>
      </section>

      <section className="hp-room-section">
        <div className="container-fluid">
          <div className="hp-room-items">
            <div className="row">
              <div className="col-lg-3 col-md-6 img-overlay">
                <div
                  className="hp-room-item set-bg"
                  style={{ backgroundImage: "url(img/room/room-b3.jpg)" }}
                >
                  <div className="hr-text">
                    <h3>Queen Room</h3>
                    <h2>
                      140$<span>/Pernight</span>
                    </h2>
                    <table>
                      <tbody>
                        <tr>
                          <td className="r-o">Fridge</td>
                          <td>Television</td>
                        </tr>
                        <tr>
                          <td className="r-o">Microwave</td>
                          <td>Electric kettle</td>
                        </tr>
                        <tr>
                          <td className="r-o">Wifi</td>
                          <td>Air conditioned</td>
                        </tr>
                        <tr>
                          <td className="r-o" colSpan={2}>
                            Linen and Towels Provided.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Link
                      to="https://apac.littlehotelier.com/reservations/huntervalleytravellersdirect/139763?locale=en"
                      target="_blank"
                      className="primary-btn"
                    >
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 img-overlay">
                <div
                  className="hp-room-item set-bg"
                  style={{ backgroundImage: "url(img/room/room-b2.jpg)" }}
                >
                  <div className="hr-text">
                    <h3>Deluxe King Room (Pet Friendly)</h3>
                    <h2>
                      150$<span>/Pernight</span>
                    </h2>
                    <table>
                      <tbody>
                        <tr>
                          <td className="r-o">Bar Fridge</td>
                          <td>Housekeeping</td>
                        </tr>
                        <tr>
                          <td className="r-o">Coffee Maker</td>
                          <td>Towels</td>
                        </tr>
                        <tr>
                          <td className="r-o">Microwave</td>
                          <td>Air conditioned</td>
                        </tr>
                        <tr>
                          <td className="r-o">Wifi</td>
                          <td>Television</td>
                        </tr>
                      </tbody>
                    </table>
                    <Link
                      to="https://apac.littlehotelier.com/reservations/huntervalleytravellersdirect/166949?locale=en"
                      target="_blank"
                      className="primary-btn"
                    >
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 img-overlay">
                <div
                  className="hp-room-item set-bg"
                  style={{ backgroundImage: "url(img/room/room-b3.jpg)" }}
                >
                  <div className="hr-text">
                    <h3>Family Room (Pet Friendly)</h3>
                    <h2>
                      220$<span>/Pernight</span>
                    </h2>
                    <table>
                      <tbody>
                        <tr>
                          <td className="r-o">Double Beds</td>
                          <td>Housekeeping</td>
                        </tr>
                        <tr>
                          <td className="r-o">Coffee Maker</td>
                          <td>Towels</td>
                        </tr>
                        <tr>
                          <td className="r-o">Microwave</td>
                          <td>Air conditioned</td>
                        </tr>
                        <tr>
                          <td className="r-o">Wifi</td>
                          <td>Television</td>
                        </tr>
                      </tbody>
                    </table>
                    <Link
                      to="https://apac.littlehotelier.com/reservations/huntervalleytravellersdirect/166950?locale=en"
                      target="_blank"
                      className="primary-btn"
                    >
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 img-overlay">
                <div
                  className="hp-room-item set-bg"
                  style={{ backgroundImage: "url(img/room/room-b4.jpg)" }}
                >
                  <div className="hr-text">
                    <h3>Queen Room (Pet Friendly)</h3>
                    <h2>
                      90$<span>/Pernight</span>
                    </h2>
                    <table>
                      <tbody>
                        <tr>
                          <td className="r-o">Fridge</td>
                          <td>Television</td>
                        </tr>
                        <tr>
                          <td className="r-o">Microwave</td>
                          <td>Electric kettle</td>
                        </tr>
                        <tr>
                          <td className="r-o">Wifi</td>
                          <td>Air conditioned</td>
                        </tr>
                        <tr>
                          <td className="r-o" colSpan={2}>
                            Linen and Towels Provided.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Link
                      to="https://apac.littlehotelier.com/reservations/huntervalleytravellersdirect/166951?locale=en"
                      target="_blank"
                      className="primary-btn"
                    >
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-text" style={{ margin: "35px 0" }}>
            <Link to="/rooms" className=" primary-btn about-btn">
              View All Rooms
            </Link>
          </div>
        </div>
      </section>

      <Testimonials />

      <section className="blog-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Attractions</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div
                className="blog-item set-bg"
                style={{ backgroundImage: "url(img/c15ixrw1n7vijel5dxsw.png)" }}
              >
                <div className="bi-text">
                  <span className="b-tag">
                    Hunter Valley Gardens- 17 mins Drive
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="blog-item set-bg"
                style={{
                  backgroundImage: "url(img/Hunter-Valley-Wildlife.png)",
                }}
              >
                <div className="bi-text">
                  <span className="b-tag">
                    Hunter Valley Wildlife Park-12 mins drive
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="blog-item set-bg"
                style={{ backgroundImage: "url(img/HOPE-ESTATE-WINERY.png)" }}
              >
                <div className="bi-text">
                  <span className="b-tag">
                    Hope Estate Winery-15 mins drive
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div
                className="blog-item small-size set-bg"
                style={{ backgroundImage: "url(img/chocolate.jpg)" }}
              >
                <div className="bi-text">
                  <span className="b-tag">
                    Hunter Valley Chocolate Company @ Twenty-3-Twenty, 2320-13
                    mins drive
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="blog-item small-size set-bg"
                style={{ backgroundImage: "url(img/HARRIGANS-WINERY.png)" }}
              >
                <div className="bi-text">
                  <span className="b-tag">Harrigans Winery-16 mins drive</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="blog-item small-size set-bg"
                style={{ backgroundImage: "url(img/Horse.jpg)" }}
              >
                <div className="bi-text">
                  <span className="b-tag">
                    Hunter Valley Horse Riding & Adventures- 20 mins drive
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div
                className="blog-item small-size set-bg"
                style={{
                  backgroundImage: "url(img/hot-air-balloon-1.png)",
                  backgroundPositionY: "center",
                }}
              >
                <div className="bi-text">
                  <span className="b-tag">
                    Hot Air Balloon Ride & Breakfast - Hunter Valley-13 mins
                    drive
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
