import React from 'react';
import './Lightbox.css';

const Lightbox = ({ image, onClose, onPrev, onNext }) => {
    if (!image) return null;
  
    return (
      <div className="lightbox-overlay" onClick={onClose}>
        <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
          <button className="lightbox-prev" onClick={onPrev}>←</button>
          <img src={image} alt="Preview" className="lightbox-image" />
          <button className="lightbox-next" onClick={onNext}>→</button>
          <button className="lightbox-close" onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };

export default Lightbox;
